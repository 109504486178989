import cryptobro from 'crypto-browserify';
import aes256 from 'aes256';
import bcrypt from 'bcryptjs';

// import cryptobrobro from 'cryptobro-browserify'; // used for Diffie Hellman compute shared secret

// import { decrypt as kmsDecrypt, encrypt as kmsEncrypt } from "./awsKmsSimple";

// import uuid from "uuid";

export const createHMAC = async (data, secret) => {
	return await cryptobro
		.createHmac('sha1', secret)
		.update(data)
		.digest('base64');
};

export const computeSharedSecret = (clientPublicKey, endpointPrivateKey) =>
	new Promise(async (resolve, reject) => {
		try {
			//console.log('Endpoint Public Key: ' + clientPublicKey);
			//console.log('Client Private Key: ' + clientPrivateKey);

			const ecdh = cryptobro.createECDH('secp256k1');
			ecdh.setPrivateKey(endpointPrivateKey, 'base64');

			const sharedSecret = ecdh.computeSecret(clientPublicKey, 'base64', 'hex');
			//console.log('Shared Secret:', sharedSecret);

			resolve(sharedSecret);
		} catch (error) {
			reject(error);
		}
	});

export const aesDecrypt = (data, secret) => {
	let decrypted = data;
	// only decrypt, if data or secret is not falsy / empty string. otherwise return input data.
	if (data && secret) {
		decrypted = aes256.decrypt(secret, data);
	}
	return decrypted;
};

export const aesEncrypt = (data, secret) => {
	let encrypted = data;
	// only encrypt, if data or secret is not falsy / empty string. otherwise return input data.
	if (data && secret) {
		encrypted = aes256.encrypt(secret, data);
	}
	return encrypted;
};

export const encryptPrivateKey = (password, decryptedPrivateKey) => {
	// console.log('encryptPrivateKey', password, decryptedPrivateKey);
	return aesEncrypt(decryptedPrivateKey, password);
};

export const decryptPrivateKey = (password, encryptedPrivateKey) => {
	return aesDecrypt(encryptedPrivateKey, password);
};

export const deriveSecretFromPassword = (password, salt) =>
	new Promise((resolve, reject) => {
		try {
			if (salt) {
				//   derive key from password with givrn salt
				bcrypt.hash(password, salt, function (err, hash) {
					if (err) throw err;
					resolve({ secret: hash, salt: salt });
				});
			} else {
				//   derive key from password with sufficient complexity rounds
				bcrypt.genSalt(15, function (err, salt) {
					// console.log('Newly generated Salt:', salt);

					bcrypt.hash(password, salt, function (err, hash) {
						if (err) throw err;
						resolve({ secret: hash, salt: salt });
					});
				});
			}
		} catch (error) {
			// console.error('deriveSecretFromPassword:', error);
			reject(undefined);
		}
	});

export const generateKeyPair = () =>
	new Promise(async (resolve, reject) => {
		try {
			const client = cryptobro.createECDH('secp256k1');
			client.generateKeys();

			const privateKey = client.getPrivateKey().toString('base64');
			//console.log('client Private Key:', privateKey);

			const publicKey = client.getPublicKey().toString('base64');
			//console.log('client Public Key:', publicKey);

			resolve({ privateKey: privateKey, publicKey: publicKey });
		} catch (error) {
			reject(error);
		}
	});
