import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
	Container,
	Button,
	Paper,
	Typography,
	TextField,
	Divider,
} from '@material-ui/core';

import {
	deriveSecretFromPassword,
	decryptPrivateKey,
	computeSharedSecret,
	aesEncrypt,
	createHMAC,
} from '../services/cryptoService';

import { generateApiToken } from '../services/generateApiToken';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CheckPassword(props) {
	const {
		sharedSecret,
		data,
		externalRequestId,
		identificationOneTimePassword,
		onNewDataFetched,
		onNewSharedSecretSet,
	} = props;

	const [password, setPassword] = useState(
		process.env.REACT_APP_STANDARD_PW_FOR_TESTING || ''
	);
	const [extRequestId, setExtRequestId] = useState(externalRequestId);
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(data);

	// const getRequestStatus = (externalRequestId) =>
	// new Promise((resolve, reject) => {
	//     const requestOptions = {
	//         method: 'GET',
	//         headers: { 'Content-Type': 'application/json' },
	//     };
	//     fetch(
	//         `${process.env.REACT_APP_ENDPOINT}/response/${externalRequestId}`,
	//         requestOptions
	//     )
	//         .then((response) => response.json())
	//         .then((data) => {
	//             // console.log("Data:", data);
	//             if (data) {
	//                 resolve(data.response);
	//                 //setResult(data.response);
	//                 //setLoading(false);
	//                 console.log('Fetched response status:', data);
	//             }
	//         })
	//         .catch((error) => {
	//             //setResult('Error while fetching Status.');
	//             console.log(error);
	//             reject(error);
	//         });
	// });

	useEffect(() => {
		// if a shared secret was already provided in the url as #-param
		if (sharedSecret) {
			setLoading(true);
			createHMAC('OK', sharedSecret).then((keyTest) => {
				fetchResponseDataFromAPI(externalRequestId, keyTest).then(
					(response) => {
						response.keyTest = keyTest;
						// console.log('Response data:', response);
						if (onNewSharedSecretSet) onNewSharedSecretSet(sharedSecret);
						if (onNewDataFetched) onNewDataFetched(response);
					}
				);
			});
			setLoading(false);
		}
	}, [props]);

	const fetchRequestStatusFromAPI = (externalRequestId, oneTimePassword) =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					externalRequestId: externalRequestId,
					identificationOneTimePassword: oneTimePassword,
				}),
			};
			// console.log(
			// 	'Fetching status from Backend for request: ',
			// 	externalRequestId
			// );
			fetch(
				`${process.env.REACT_APP_ENDPOINT}/request/check-status`,
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) return response.json();
					else resolve({ code: response.status });
				})
				.then((data) => {
					// console.log('Status received:', data);
					if (data) {
						resolve(data);
						// console.log('Fetched response status:', data);
					}
				})
				.catch((error) => {
					//setResult('Error while fetching Status.');
					console.log(error);
					reject(error);
				});
		});

	const fetchResponseDataFromAPI = (externalRequestId, keyTest) =>
		new Promise(async (resolve, reject) => {
			setResult(undefined);
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					externalRequestId: externalRequestId,
					requestToken: await generateApiToken(externalRequestId, keyTest),
				}),
			};
			fetch(`${process.env.REACT_APP_ENDPOINT}/response/get`, requestOptions)
				.then((response) => {
					if (response.status === 200) return response.json();
					else resolve({ code: response.status });
				})
				.then((data) => {
					// console.log("Data:", data);
					if (data) {
						resolve(data.response);
						// console.log('Fetched response status:', data.response);
					}
				})
				.catch((error) => {
					//setResult('Error while fetching Status.');
					console.log(error);
					reject(error);
				});
		});

	const handleExternalRequestIdChange = (event) => {
		event.preventDefault();
		setExtRequestId(event.target.value);
	};

	const handlePasswordChange = (event) => {
		event.preventDefault();
		setPassword(event.target.value);
	};

	const decryptUserPrivateKey = async (pw, salt, encryptedUserPrivateKey) => {
		// console.log(pw, salt, encryptedUserPrivateKey);
		if (pw && salt && encryptedUserPrivateKey) {
			const decryptedPassword = await deriveSecretFromPassword(pw, salt);
			// console.log('decryptedPassword', decryptedPassword);
			const decryptedPrivateKey = await decryptPrivateKey(
				decryptedPassword.secret,
				encryptedUserPrivateKey
			);
			// console.log(decryptedPrivateKey);
			return decryptedPrivateKey;
		}
		return null;
	};

	const handleButtonClick = async (event) => {
		event?.preventDefault();
		setLoading(true);

		let salt = data?.salt;
		let encryptedUserPrivateKey = data?.encryptedUserPrivateKey;
		let clientPublicKey = data?.clientPublicKey;

		// first fetch data in case externalRequestId was changed on this form (compared to what may have been given as a site parameter previously)

		let res = undefined;
		// console.log('pros id:', props.externalRequestId);
		// console.log('form id:', extRequestId);
		if (props.externalRequestId !== extRequestId) {
			console.log('Fetching fresh status...');
			res = await fetchRequestStatusFromAPI(extRequestId);
		}
		if (res) {
			salt = res?.salt;
			encryptedUserPrivateKey = res?.encryptedUserPrivateKey;
			clientPublicKey = res?.clientPublicKey;
		}
		// console.log('Status check result:', res);

		if (!res?.code) {
			console.log('Valid data received');
			let sharedSecret = 'initial_value';
			let keyTest = undefined;
			if (salt && encryptedUserPrivateKey) {
				const decryptedPrivateKey = await decryptUserPrivateKey(
					password,
					salt,
					encryptedUserPrivateKey
				);
				// console.log('decryptedPrivateKey', decryptedPrivateKey);

				sharedSecret = await computeSharedSecret(
					clientPublicKey,
					decryptedPrivateKey
				);
				console.log('Shared secret:', sharedSecret);

				keyTest = await createHMAC('OK', sharedSecret);
				// console.log('keyTest', keyTest);
			}

			res = await fetchResponseDataFromAPI(extRequestId, keyTest);
			res.keyTest = keyTest;
			// console.log('Response data:', res);
			if (onNewSharedSecretSet) onNewSharedSecretSet(sharedSecret);
		}
		setLoading(false);

		if (onNewDataFetched) onNewDataFetched(res);
	};

	const handleKeypress = (e) => {
		//it triggers by pressing the enter key
		// console.log('Key pressed: ' + e.keyCode);
		if (e.keyCode === 13) {
			handleButtonClick();
		}
	};

	return (
		<Container maxWidth='lg' className={'py-3'}>
			{/* <Paper className='card shadow-lg border-0 mt-5 mb-3 text-start p-4'> */}
			<div className='card shadow-lg border-0 mt-5 mb-3 text-start p-4'>
				<h3>Ihre Betroffenenrechtsanfrage</h3>
				<Divider className='mb-3 mt-2' />
				<p>
					Bitte melden Sie Sich mit Ihrer Anfrage-ID und dem von Ihnen
					individuell festgelegten Passwort an.
				</p>
				<TextField
					name='externalRequestId'
					value={extRequestId}
					label='Anfrage'
					className='mb-3'
					onChange={handleExternalRequestIdChange}
				/>
				<TextField
					id='textfieldPassword'
					name='password'
					value={password}
					label='Passwort'
					onChange={handlePasswordChange}
					className='mb-3'
					type='password'
					onKeyDown={handleKeypress}
				/>
				<Button
					id='buttonCheckPassword'
					variant='contained'
					color='primary'
					onClick={handleButtonClick}
					onKeyPress={handleKeypress}
					disabled={loading || password?.length === 0}
				>
					{loading ? (
						// <FontAwesomeIcon icon='fa-duotone fa-circle-notch' />
						<div>
							<FontAwesomeIcon icon='fa-solid fa-circle-notch' spin />
						</div>
					) : (
						// <FontAwesomeIcon icon={['fas', 'check-circle']}></FontAwesomeIcon>
						<b>Status prüfen</b>
					)}
				</Button>
			</div>
			{/* </Paper> */}
		</Container>
	);
}

CheckPassword.propTypes = {};

export default CheckPassword;
