import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Paper, Divider, Button, Collapse } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import BlockEditor from './editor/BlockEditor';
import { generateApiToken } from '../services/generateApiToken';
import { aesEncrypt, createHMAC } from '../services/cryptoService';

function SendMessage(props) {
	const {
		sharedSecret,
		controllerName,
		externalRequestId,
		onMessageSent,
		onCancelMessage,
	} = props;

	const [operationInProgress, setOperationInProgress] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	if (!sharedSecret || sharedSecret === 'initial_value')
		return <div>Kein Shared Secret vorhanden.</div>;
	// console.log('sharedSecret: ', sharedSecret);

	const sendMessageToBackend = (message, externalRequestId, encrypted) =>
		new Promise(async (resolve, reject) => {
			let keyTest = await createHMAC('OK', sharedSecret);

			generateApiToken(externalRequestId, keyTest).then((apiToken) => {
				console.log('apiToken:', apiToken);

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						requestToken: apiToken,
						externalRequestId,
						message,
						format: 'editorjs',
						encrypted,
						type: 10,
					}),
				};
				setOperationInProgress(true);
				fetch(
					`${process.env.REACT_APP_ENDPOINT}/request/send-new-message`,
					requestOptions
				)
					.then((response) => {
						// if (response.status === 200) return response.json();
						// else resolve({ status: response.status });

						return { ...response.json(), status: response?.status };
					})
					.then((data) => {
						// console.log("Data:", data);
						if (data) {
							// resolve(data.response);
							//setResult(data.response);
							setOperationInProgress(false);

							console.log('Fetched response status:', data);
							setShowSuccessMessage(true);
							resolve(data);
						}
					})
					.catch((error) => {
						//setResult('Error while fetching Status.');
						setOperationInProgress(false);
						console.log(error);
						reject(error);
					});
			});
		});

	const handleEditorSendMessage = async (message) => {
		console.log('Message to send:', message);

		let encryptedMessage = await aesEncrypt(
			JSON.stringify(message),
			sharedSecret
		);

		sendMessageToBackend(encryptedMessage, externalRequestId, true)
			.then((data) => {
				console.log('Message sent.');
				onMessageSent && onMessageSent(message);
				setShowSuccessMessage(true);
				setTimeout(() => {
					setShowSuccessMessage(false);
				}, 3000);
			})
			.catch((error) => {
				console.log('Error while sending message:', error);
			});
	};

	return (
		<>
			{/* <Container maxWidth='lg' className={''}> */}
			<Paper className='card shadow-lg border-0 mt-5 mb-3 text-start p-4'>
				<h5>Neue Nachricht an {controllerName} senden</h5>
				<Divider className='mb-3 mt-2' />
				<BlockEditor
					saveButtonCaption='Senden'
					showSaveButtonSpinner={operationInProgress}
					onSave={handleEditorSendMessage}
					onClose={onCancelMessage}
				/>
			</Paper>

			{showSuccessMessage && (
				<MuiAlert severity='success'>
					Ihre Nachricht wurde erfolgreich versendet.
				</MuiAlert>
			)}
			{/* </Container> */}
		</>
	);
}

SendMessage.propTypes = {};

export function SendMessageButton(props) {
	// const orientation = props.orientation || 'right';

	const [open, setOpen] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const handleClickOpen = () => {
		setOpen((prev) => !prev);
	};

	const handleMessageSent = () => {
		setOpen(false);
		setShowSuccessMessage(true);
		setTimeout(() => {
			setShowSuccessMessage(false);
		}, 3000);
	};

	return (
		<div>
			<Container maxWidth='lg' className='my-3'>
				<div className='d-flex justify-content-end'>
					<Button
						className='m-0'
						variant='outlined'
						onClick={handleClickOpen}
						// color='primary'
						size='small'
					>
						Mitteilung senden
					</Button>
				</div>
				<Collapse in={open}>
					<SendMessage
						{...props}
						onCancelMessage={() => setOpen(false)}
						onMessageSent={handleMessageSent}
					/>
				</Collapse>
				<Collapse in={showSuccessMessage}>
					<MuiAlert severity='success'>
						Ihre Nachricht wurde erfolgreich versendet.
					</MuiAlert>
				</Collapse>
			</Container>
		</div>
	);
}

export default SendMessage;
