import React from 'react';
import PropTypes from 'prop-types';
import {
	Container,
	Paper,
	Divider,
	List,
	ListItem,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { aesDecrypt } from '../services/cryptoService';

import DOMPurify from 'dompurify';
import edjsParser from 'editorjs-parser';
import { prepareMessage } from '../services/prepareMessages';
// import BlockEditor from './editor/BlockEditor';

function ShowMessageList(props) {
	const { messages, sharedSecret, controllerName } = props;

	if (!sharedSecret || sharedSecret === 'initial_value')
		return <div>Kein Shared Secret vorhanden.</div>;
	// console.log('sharedSecret: ', sharedSecret);

	return (
		<>
			<Container maxWidth='lg' className={''}>
				<Paper className='card shadow-lg border-0 mt-5 mb-3 text-start p-4'>
					<h5>Mitteilungen</h5>
					<Divider className='mb-3 mt-2' />

					{messages.map((message, index) => {
						// console.log('message: ', message);

						let msgObj = prepareMessage(message, sharedSecret);

						return (
							<div key={index + '-' + message._id}>
								<Accordion elevation={0} className='m-0 p-0' disableGutters>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										key={'messages-summary-' + index}
										className='m-0 p-0 '
									>
										<Grid container spacing={0} className='p-0 '>
											<Grid item xs>
												{/* Headline */}
												{message.type == 10 && (
													<>
														{/* <ArrowRightIcon
															fontSize='small'
															className='mr-2 text-info'
														/>{' '} */}
													</>
												)}
												{message.type == 20 && (
													<>
														{/* <ArrowLeftIcon
															fontSize='small'
															className='mr-2 text-info'
														/>{' '} */}
													</>
												)}
												{message.type == 30 && (
													<>
														{/* <ArrowLeftIcon
															fontSize='small'
															className='mr-2 text-info'
														/>{' '} */}
														<ErrorOutlineIcon
															fontSize='small'
															className='mr-2 text-danger'
														/>{' '}
													</>
												)}
												<b>
													<span
														className='w-100'
														dangerouslySetInnerHTML={{
															__html: msgObj.headline,
														}}
													/>
												</b>
											</Grid>
											<Grid item xs={12} sm={4}>
												{message?.format === 'html' &&
													(!message.author || message.author === '') && (
														<span>Systemgenerierte Mitteilung</span>
													)}
												{message.type == 10 && (
													<div>Ihre Nachricht an {controllerName}</div>
												)}
												{message.type == 20 && (
													<span>Nachricht von {controllerName}</span>
												)}
												{message.type == 30 && (
													<div>Rückfrage von {controllerName}</div>
												)}

												{message.date ? (
													<div>
														{new Intl.DateTimeFormat('de', {
															dateStyle: 'full',
															timeStyle: 'long',
														}).format(new Date(message.date))}
													</div>
												) : (
													''
												)}
											</Grid>
										</Grid>
									</AccordionSummary>

									<AccordionDetails className='p-0'>
										<div>
											<div
												className='my-2 w-100 '
												dangerouslySetInnerHTML={{
													__html: msgObj.body,
												}}
											/>
										</div>
									</AccordionDetails>
								</Accordion>
								{index < messages.length - 1 && <Divider className='my-1' />}
							</div>
						);
					})}
				</Paper>
			</Container>
		</>
	);
}

ShowMessageList.propTypes = {};

export default ShowMessageList;
