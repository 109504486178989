module.exports = {
	0: {
		de: { label: 'Anfrage auf Auskunft nach Art. 15 DSGVO' },
		en: { label: 'Request for Information acc. to Art. 15 EU-GDPR' },
	},
	1: {
		de: { label: 'Anfrage auf Übertragung der Daten nach Art. 20 DSGVO' },
		en: { label: 'Request for data portability acc. to Art. 20 EU-GDPR' },
	},
	2: {
		de: {
			label:
				'Anfrage über die Einschränkung der Verarbeitung nach Art. 18 DSGVO',
		},
		en: {
			label: 'Request for restriction on processing acc. to Art. 18 EU-GDPR',
		},
	},
	3: {
		de: {
			label:
				'Mitteilung über den Widerspruch der Datenverarbeitung nach Art. 21 DSGVO',
		},
		en: { label: 'Objection to processing acc. to Art. 21 EU-GDPR' },
	},
	4: {
		de: {
			label:
				'Anfrage über die Richtigstellung Ihrer personenbezogenen Daten nach Art. 16 DSGVO',
		},
		en: {
			label:
				'Request for rectification of your personal data acc. to Art. 16 EU-GDPR',
		},
	},
	5: {
		de: {
			label:
				'Anfrage über die Löschung Ihrer personenbezogenen Daten nach Art. 17 DSGVO',
		},
		en: {
			label:
				'Request for deletion of your personal data acc. to Art. 17 EU-GDPR',
		},
	},
};
