const { generateClientKeys, computeSharedSecret } = require('./cryptoService');
const {
	generateAccessTokenFromExternalRequestId,
} = require('./generateApiToken');

export async function createSharedSecretFromFreshKeypair(clientPublicKey) {
	// generate user's pki keys
	const { privateKey: userPrivateKey, publicKey: userPublicKey } =
		await generateClientKeys();
	console.log('Users private key', userPrivateKey);

	const sharedSecret = await computeSharedSecret(
		clientPublicKey,
		userPrivateKey
	);

	// use user's private key and client's public key to generate a shared secret with ECDH

	// symmetrically encryp identification data with shared secret

	return { sharedSecret, userPublicKey, userPrivateKey };
}
