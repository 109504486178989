import '../App.css';

import React, { useState, useEffect } from 'react';

import dateFormat from 'dateformat';

import { makeStyles } from '@material-ui/core/styles';
import {
	Paper,
	Grid,
	Box,
	Container,
	Button,
	Collapse,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import edjsParser from 'editorjs-parser';

import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { jsPDF } from 'jspdf';

import Crypto from 'crypto-js';
import { aesDecrypt } from '../services/cryptoService';
import { generateApiToken } from '../services/generateApiToken';

import DOMPurify from 'dompurify';
import { getMessagesAsHTMLTable } from '../services/prepareMessages';
import ShowMessageList from './ShowMessageList';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const sanitizer = DOMPurify.sanitize;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: theme.spacing(160),
			height: theme.spacing(200),
		},
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(3),
	},
	container: {
		marginTop: theme.spacing(2),
	},

	paper: {
		textAlign: 'left',
		padding: theme.spacing(8),
		marginBottom: theme.spacing(1),
	},
}));

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

function ShowResult(props) {
	const classes = useStyles();

	const { data, sharedSecret, keyTest } = props;

	// const [data, setResult] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [showAllMessages, setShowAllMessages] = useState(false);

	// const { id } = props;

	const decryptData = (data, key) => {
		if (data && key) {
			return aesDecrypt(data, key);
		} else {
			return undefined;
		}
		// const bytes = Crypto.AES.decrypt(data, key);
		// return bytes.toString(Crypto.enc.Utf8);
	};

	const convertWordArrayToUint8Array = (wordArray) => {
		try {
			var arrayOfWords = wordArray.hasOwnProperty('words')
				? wordArray.words
				: [];
			var length = wordArray.hasOwnProperty('sigBytes')
				? wordArray.sigBytes
				: arrayOfWords.length * 4;
			var uInt8Array = new Uint8Array(length),
				index = 0,
				word,
				i;
			for (i = 0; i < length; i++) {
				word = arrayOfWords[i];
				uInt8Array[index++] = word >> 24;
				uInt8Array[index++] = (word >> 16) & 0xff;
				uInt8Array[index++] = (word >> 8) & 0xff;
				uInt8Array[index++] = word & 0xff;
			}
			return uInt8Array;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	const decryptFile = (file, key) => {
		return new Promise(function (resolve, reject) {
			var reader = new FileReader();
			reader.onload = () => {
				var decrypted = Crypto.AES.decrypt(reader.result, key); // Decryption: I: Base64 encoded string (OpenSSL-format) -> O: WordArray
				var typedArray = convertWordArrayToUint8Array(decrypted); // Convert: WordArray -> typed array
				//var typedArray = decrypted; // Convert: WordArray -> typed array
				var fileDec = new Blob([typedArray]); // Create blob from typed array
				resolve(fileDec);
			};
			reader.readAsText(file);
		});
	};

	const saveToZip = (filename, urls) => {
		const zip = new JSZip();
		const folder = zip.folder('project');
		urls.forEach((url) => {
			const blobPromise = fetch(url).then((r) => {
				if (r.status === 200) return r.blob();
				return Promise.reject(new Error(r.statusText));
			});
			const name = url.substring(url.lastIndexOf('/'));
			folder.file(name, blobPromise);
		});

		zip
			.generateAsync({ type: 'blob' })
			.then((blob) => saveAs(blob, filename))
			.catch((e) => console.log(e));
	};

	const downloadAndZipAllFiles = (attachments) =>
		new Promise(async (resolve, reject) => {
			try {
				const zip = new JSZip();

				await Promise.all(
					attachments.map(
						async (payload) =>
							new Promise(async (resolve, reject) => {
								const requestOptions = {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify({
										file: payload.url,
										externalRequestId: data.externalRequestId,
										requestToken: await generateApiToken(
											data.externalRequestId,
											data.keyTest
										),
									}),
								};

								const attachmentUrl = await fetch(
									`${process.env.REACT_APP_ENDPOINT}/response/get-attachment-url`,
									requestOptions
								);

								const signedUrl = await attachmentUrl.json();

								console.log('URL:' + signedUrl);

								const file = await fetch(signedUrl);
								const blob = await file.blob();

								let decBlob = await decryptFile(blob, getDecryptionKey());
								if (decBlob) {
									console.log('Adding to ZIP...');
									zip.file(payload.name, decBlob);
									// return true;
								}
								resolve(true);
							})
					)
				);

				console.log('Saving ZIP...');
				zip
					.generateAsync({ type: 'blob' })
					.then((blob) => saveAs(blob, 'Betroffenenrechtsanfrage.zip'))
					.catch((e) => console.log(e));
				resolve();
				// });
			} catch (error) {
				console.error(error);
				reject('Could not get file from API. Perhaps wrong password.');
			}
		});

	const downloadAndSaveFile = (payload) =>
		new Promise(async (resolve, reject) => {
			try {
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						file: payload.url,
						externalRequestId: data.externalRequestId,
						requestToken: await generateApiToken(
							data.externalRequestId,
							data.keyTest //'9KuK9BRTANCJcIaP+Muo5n5aMOI='
						),
					}),
				};

				const attachmentUrl = await fetch(
					`${process.env.REACT_APP_ENDPOINT}/response/get-attachment-url`,
					requestOptions
				);

				const signedUrl = await attachmentUrl.json();

				console.log('URL:' + signedUrl);

				const file = await fetch(signedUrl);
				const blob = await file.blob();

				//let blob = new Blob([file.body], { type: file.ContentType });
				decryptFile(blob, getDecryptionKey()).then((decBlob) => {
					if (decBlob) {
						// download data
						let link = document.createElement('a');
						link.href = window.URL.createObjectURL(decBlob);
						link.download = payload.name;
						link.click();
						window.URL.revokeObjectURL(link.href);
					}
				});
				resolve();
			} catch (error) {
				console.error(error);
				reject('Could not get file from API. Perhaps wrong password.');
			}
		});

	const downloadResponseAsPDF = async (content) => {
		console.log('Download...');

		const pdf = new jsPDF({ unit: 'px', hotfixes: ['px_scaling'] });
		const a4Width = Number(pdf.internal.pageSize.getWidth());
		const a4Height = Number(pdf.internal.pageSize.getHeight());

		let allAttachments = getAttachmentOverview(data);

		let allMessagesAsHTML = getMessagesAsHTMLTable(
			data.messages,
			sharedSecret,
			data?.controllerName
		);

		// content += '<br><br><br>' + allMessagesAsHTML;

		pdf.html(`<div style="width:${a4Width - 140}px;">${content}</div>`, {
			margin: [50, 25, 50, 25],
			x: 50,
			y: 50,
			pagesplit: true,
			autoPaging: 'text',
			width: a4Width - 140,
			// retina: true,
			callback: (pdf) => {
				let pages = pdf.internal.getNumberOfPages();
				pdf.html(
					`<div style="width:${a4Width - 140}px;">${allAttachments}</div>`,
					{
						margin: [50, 25, 50, 25],
						x: 50,
						y: 50 + pages * a4Height - pages * 50 * 2,
						pagesplit: true,
						autoPaging: 'text',
						width: a4Width - 140,
						// retina: true,
						callback: (pdf) => {
							let pages = pdf.internal.getNumberOfPages();
							pdf.html(
								`<div style="width:${
									a4Width - 140
								}px;">${allMessagesAsHTML}</div>`,
								{
									margin: [50, 25, 50, 25],
									x: 50,
									y: 50 + pages * a4Height - pages * 50 * 2,
									pagesplit: true,
									autoPaging: 'text',
									width: a4Width - 140,
									// retina: true,
									callback: (pdf) => {
										// add footer
										let totalPages = pdf.internal.getNumberOfPages();

										for (let i = 1; i <= totalPages; i++) {
											pdf.setPage(i);
											pdf.setFontSize(10);
											pdf.setTextColor(150);
											pdf.text(
												'Ihre Betroffenenrechtsanfrage | Seite ' +
													i +
													' of ' +
													totalPages,
												pdf.internal.pageSize.getWidth() - 320,
												pdf.internal.pageSize.getHeight() - 30
											);
										}
										pdf.save('Betroffenenrechtsanfrage.pdf');
									},
								}
							);
						},
					}
				);
			},
		});
	};

	const getDecryptionKey = () => {
		// if a decryption key was already provided via props, use this; otherwise check for site parameters
		if (sharedSecret) return sharedSecret;

		const locationArray = window.location.href.split('#');
		if (locationArray.length > 1) {
			// console.log("Key:", locationArray[1]);
			if (locationArray[1].length === 64) return locationArray[1];
			else return undefined;
		}
		return undefined;
	};

	// console.log('data:', data);

	const getAttachmentOverview = (response) => {
		if (response?.attachments?.length > 0) {
			let attachmentOverview = `<h3>Anhänge zum Download</h3><ul>`;
			response.attachments.forEach((attachment) => {
				attachmentOverview += `<li>${attachment.name}</li>`;
			});
			attachmentOverview += `</ul>`;
			attachmentOverview += `<br>Hinweis: Die Anhänge werden aus Gründen der Datensicherheit nach Ablauf von 30 Tagen nach dem Versand der Antwort unwiderbringlich gelöscht. Wenn Sie diese Anhänge nutzen möchten, müssen Sie diese selbsttätig von unserem Portal herunterladen.`;
			return attachmentOverview;
		}
		return `<h3>Anhänge zum Download</h3><br>Keine`;
	};

	if (data) {
		let response = '';

		let parser = '';
		let markup = 'No response text available.';

		// const decKey = getDecryptionKey();
		// const decKey = sharedSecret;

		if (!sharedSecret) markup = 'No valid key provided.';

		if (data.response && sharedSecret) {
			response = decryptData(data.response, sharedSecret);

			parser = new edjsParser();
			markup = '';
			if (new Date(data?.responseSentDate).getTime() > 0)
				// show date when response was sent to user
				markup += `<p>Datum: 
				${new Intl.DateTimeFormat('default', {
					dateStyle: 'full',
				}).format(new Date(data?.responseSentDate))}</p>`;

			markup += parser.parse(JSON.parse(response));
		}
		return (
			<>
				<ClipLoader
					color={'#3273a8'}
					loading={loading}
					css={override}
					size={150}
				/>
				<div>
					<Container maxWidth='lg' className={classes.container}>
						<MuiAlert severity='error'>
							Um Ihre sensiblen Daten zu schützen, werden diese Antwort und alle
							angehängten Dateien automatisch 30 Tage nachdem sie an Sie
							gesendet wurden unwiederbringlich gelöscht. Wenn Sie diese
							Informationen aufbewahren möchten, laden Sie bitte die Nachricht
							und alle Anhänge herunter. Innerhalb von 30 Tagen können Sie diese
							Seite so oft wie nötig aufrufen.
						</MuiAlert>
					</Container>
					{/* {data?.messages?.length > 0 && (
						<ShowMessageList
							sharedSecret={sharedSecret}
							messages={data?.messages}
							controllerName={data?.clientName}
						/>
					)} */}
					<Container maxWidth='lg' className={classes.container}>
						<Paper className='card shadow-lg border-0 mb-3 text-start p-4'>
							<div
								id='content'
								className={classes.content}
								dangerouslySetInnerHTML={{
									__html: sanitizer(markup),
								}}
							></div>
						</Paper>
					</Container>
					{sharedSecret && (
						<>
							<Container maxWidth='lg' className={classes.container}>
								<Paper className='card shadow-lg border-0 mb-3 text-start p-4'>
									<div className={classes.content}>
										{/* <p>
										<Button
											variant='outlined'
											size='small'
											onClick={() => downloadResponseAsPDF(markup)}
											style={{ width: 300 }}
										>
											<b>Als PDF herunterladen</b>
										</Button>
									</p> */}
										{/* <p>
										<Button
											variant='outlined'
											size='small'
											onClick={() => downloadResponseAsPDF(markup)}
											style={{ width: 300 }}
										>
											<b>Download all attachments as ZIP</b>
										</Button>
									</p> */}
										{data.attachments && data.attachments.length > 0 && (
											<>
												<b>Attachments:</b>

												<p>
													{data.attachments.map((att) => {
														return (
															<li key={att._id}>
																<Button
																	variane='text'
																	onClick={async () =>
																		await downloadAndSaveFile(att)
																	}
																>
																	{att.name}
																</Button>
															</li>
														);
													})}
												</p>
											</>
										)}
									</div>
								</Paper>

								<div className='my-3 w-100'>
									<Button
										fullWidth
										variant='outlined'
										size='small'
										onClick={() => downloadAndZipAllFiles(data.attachments)}
										style={{ width: 300 }}
										className='mx-3'
									>
										<b>Alle Anhäge als ZIP herunterladen</b>
									</Button>

									<Button
										fullWidth
										variant='outlined'
										size='small'
										onClick={() => downloadResponseAsPDF(markup)}
										style={{ width: 300 }}
										className='mx-3'
									>
										<b>Antwort als PDF herunterladen</b>
									</Button>

									<Button
										fullWidth
										variant='outlined'
										size='small'
										onClick={() => setShowAllMessages((prev) => !prev)}
										style={{ width: 300 }}
										className='mx-3'
									>
										<b>Alle Nachrichten einblenden</b>
									</Button>
								</div>

								{/* <Paper className='card shadow-lg border-0 mb-3 text-start p-4'>
									Messages
									<div
										id='content'
										className={classes.content}
										dangerouslySetInnerHTML={{
											__html: getMessagesAsHTMLTable(
												data.messages,
												sharedSecret,
												data?.controllerName
											),
										}}
									></div>
								</Paper> */}
							</Container>
							<Collapse in={showAllMessages}>
								<ShowMessageList
									messages={data.messages}
									sharedSecret={sharedSecret}
									className='mb-5'
								/>
							</Collapse>
						</>
					)}
				</div>
			</>
		);
	} else {
		return <p>No data available, please try again later.</p>;
	}
}

export default ShowResult;
