import './App.css';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { Container, Paper, AppBar, Toolbar, Divider } from '@material-ui/core';
import logo from './logo.svg';

import MuiAlert from '@material-ui/lab/Alert';

import ClipLoader from 'react-spinners/ClipLoader';

import qs from 'query-string';
import ShowResult from './components/ShowResult';
import InitiateIdentification from './components/initiateIdentification';
import SetNewPassword from './components/setNewPassword';

import { ThemeProvider } from '@material-ui/core/styles';

// import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import CheckPassword from './components/checkPassword';
import ShowMessageList from './components/ShowMessageList';
import { SendMessageButton } from './components/SendMessage';

library.add(fas, far);

const theme = createTheme({
	palette: {
		primary: {
			main: '#0f4354',
		},
		secondary: {
			main: '#6200EE',
		},
		error: {
			main: '#D0180B',
		},
	},
});

// const theme = {
// 	palette: {
// 		primary: {
// 			light: '#757ce8',
// 			main: '#3f50b5',
// 			dark: '#002884',
// 			contrastText: '#fff',
// 		},
// 		secondary: {
// 			light: '#ff7961',
// 			main: '#f44336',
// 			dark: '#ba000d',
// 			contrastText: '#000',
// 		},
// 	},
// };

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	backgroundDark: {
		backgroundColor: '#0f4354',
	},

	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	TextField: {
		color: '#0f4354',
	},
}));

const parsedParams = qs.parse(window.location.search);

function App(props) {
	const classes = useStyles();

	const [result, setResult] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [sharedSecret, setSharedSecret] = useState(undefined);
	const [keyTest, setKeyTest] = useState(undefined);

	const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
	const [showPasswordForm, setShowPasswordForm] = useState(false);
	const [showIdentificationForm, setShowIdentificationForm] = useState(false);
	const [showInProgress, setShowInProgress] = useState(false);
	const [showNotFound, setShowNotFound] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const [showNotAuthorized, setShowNotAuthorized] = useState(false);
	const [showMessages, setShowMessages] = useState(false);

	const fetchRequestStatusFromAPI = (externalRequestId) =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					externalRequestId: externalRequestId,
				}),
			};
			fetch(
				`${process.env.REACT_APP_ENDPOINT}/request/check-status`,
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) return response.json();
					else resolve({ code: response.status });
				})
				.then((data) => {
					// console.log("Data:", data);
					if (data) {
						resolve(data);
						// console.log('Fetched response status:', data);
					}
				})
				.catch((error) => {
					//setResult('Error while fetching Status.');
					console.log(error);
					reject(error);
				});
		});

	const handleRefreshRequestData = () => {
		setLoading(true);
		console.log('Refresh Request Data');

		fetchRequestStatusFromAPI(parsedParams.id).then((data) => {
			setResult(data);
			// console.log('Data:', data);
			setLoading(false);
		});
	};

	useEffect(() => {
		if (parsedParams.id) {
			setLoading(true);

			fetchRequestStatusFromAPI(parsedParams.id).then((data) => {
				setResult(data);
				// console.log('Data:', data);
				setLoading(false);
			});
		}
	}, []);

	// ############################################################
	// determine which segment to display
	// ############################################################

	const segmentController = () => {
		setShowNewPasswordForm(false);
		setShowIdentificationForm(false);
		setShowPasswordForm(false);
		setShowResults(false);
		setShowNotFound(false);
		setShowInProgress(false);
		setShowNotAuthorized(false);
		setShowMessages(false);

		if (result?.processingStatus === 220) {
			setShowIdentificationForm(true);
		}
		if (result?.processingStatus === 230) {
			setShowNewPasswordForm(true);
		}
		if (
			!sharedSecret &&
			result?.processingStatus >= 250 &&
			// result?.processingStatus >= 290 &&
			result?.processingStatus < 700
		) {
			setShowPasswordForm(true);
		}
		if (
			sharedSecret &&
			result?.processingStatus >= 250 &&
			// result?.processingStatus >= 290 &&
			result?.processingStatus < 700
		) {
			setShowInProgress(true);
			setShowMessages(true);
		}
		if (sharedSecret && result?.processingStatus >= 700) setShowResults(true);
		if (!sharedSecret && result?.processingStatus >= 700)
			setShowPasswordForm(true);

		if (sharedSecret && result?.code === 401) {
			setShowNotAuthorized(true);
			setShowPasswordForm(true);
		}
		if (sharedSecret && result?.code === 403) {
			setShowNotAuthorized(true);
			setShowPasswordForm(true);
		}
		if (sharedSecret && result?.code === 404) {
			setShowNotFound(true);
			setShowPasswordForm(true);
		}
		if (sharedSecret && result?.code === 400) {
			setShowNotFound(true);
			setShowPasswordForm(true);
		}

		if (!result?.processingStatus) setShowPasswordForm(true);

		// if no ID was provided in params, show form to enter id and pw
		if (!parsedParams?.id) setShowPasswordForm(true);

		// in case initial status check already shows not found, have a pw form displayed
		if (!sharedSecret && !result?.processingStatus === 220)
			setShowPasswordForm(true);
	};

	useEffect(() => {
		segmentController();
	}, [result]);

	// const handlePasswortProvided = async (
	// 	externalRequestId,
	// 	sharedSecret,
	// 	keyTest
	// ) => {
	// 	console.log('Back at parent component.');
	// 	console.log('SharedSecret:', sharedSecret);
	// 	console.log('keyTest:', keyTest);

	// 	setSharedSecret(sharedSecret);
	// 	setKeyTest(keyTest);
	// 	if (externalRequestId && sharedSecret && keyTest) {
	// 		const res = await fetchResponseDataFromAPI(externalRequestId, keyTest);
	// 		// console.log('Fetching Data with PW:', res);
	// 		setResult(res);
	// 	} else {
	// 		setResult({ code: 400 });
	// 	}
	// };

	const handleNewDataFetched = (data) => {
		// console.log('New data arrived from password-component:', data);
		if (data) {
			setResult(data);
		}
	};

	const handleNewSharedSecretSet = (sharedSecret) => {
		setSharedSecret(sharedSecret);
	};

	// console.log('Parsed parameters:', parsedParams);
	// console.log('result:', result);

	if (loading)
		return (
			<div className='App'>
				<MuiThemeProvider theme={theme}>
					<AppBar position='static' className={classes.backgroundDark}>
						<Toolbar>
							<div className='d-flex justify-content-center align-items-center'>
								<h3 className='text-white'>{result?.clientName}</h3>
							</div>

							{/* <Button color='inherit' variant={'outlined'}>
						<b>Download All</b>
					</Button> */}
						</Toolbar>
					</AppBar>
					<div className='mt-5'>
						<ClipLoader
							color={'#0f4354'}
							loading={(!result && parsedParams === {}) || loading}
							// css={override}
							size={75}
						/>
					</div>
				</MuiThemeProvider>
			</div>
		);

	return (
		<div className='App'>
			<MuiThemeProvider theme={theme}>
				<AppBar position='static' className={classes.backgroundDark}>
					<Toolbar>
						<div className='d-flex justify-content-center align-items-center'>
							<h3 className='text-white'>{result?.clientName}</h3>
						</div>

						{/* <Button color='inherit' variant={'outlined'}>
						<b>Download All</b>
					</Button> */}
					</Toolbar>
				</AppBar>
				<ClipLoader
					className='mt-5'
					color={'#0f4354'}
					loading={(!result && parsedParams === {}) || loading}
					// css={override}
					size={75}
				/>
				{/* 
				<div className='mx-4'>
					<Container maxWidth='lg' className={''}>
						<Paper className='card card-box border-1 mt-5 mb-3 text-start p-4'>
							<h3>Mitteilungen</h3>
							<Divider className='mb-3 mt-2' />
							<BlockEditor
								initialMessage={'Ihre Nachricht an ' + result?.clientName}
							/>
						</Paper>
					</Container>
				</div> */}

				{showIdentificationForm && (
					<InitiateIdentification
						data={result}
						onSubmitted={() => setShowIdentificationForm(false)}
						oneTimePassword={parsedParams?.otp}
					/>
				)}

				{showNewPasswordForm && (
					<SetNewPassword
						data={result}
						onSubmitted={() => setShowNewPasswordForm(false)}
						oneTimePassword={parsedParams?.otp}
					/>
				)}

				{/* show password check if request is being authorized / identified to
				allow user to fetch more information */}
				{showPasswordForm && (
					<>
						<CheckPassword
							sharedSecret={window.location.href.split('#')[1]}
							data={result}
							externalRequestId={parsedParams.id}
							// onPasswordProvided={handlePasswortProvided}
							onNewDataFetched={handleNewDataFetched}
							onNewSharedSecretSet={handleNewSharedSecretSet}
						/>
					</>
				)}

				{/* if password has been provided and status is "in progress", show details form */}
				{showInProgress && (
					// if password was correct (fetched data has success == =true) show details; else show bounce message

					<>
						<Container maxWidth='lg' className={classes.container}>
							<h3 className='mt-4 mb-5'>Ihre Betroffenenrechtsanfrage</h3>
							<Paper className='card shadow-lg border-0 mt-5 mb-3 text-start p-4 '>
								<h5 className=''>Aktueller Status</h5>
								<Divider className='mb-3 mt-2' />
								<p>
									<b>
										Ihre Anfrage{' '}
										{result?.clientName && `bei ${result?.clientName}`} befindet
										sich derzeit in Bearbeitung.
									</b>
									<br />
									Sie werden benachrichtigt, sobald die Bearbeitung
									abgeschlossen wurde.
								</p>
							</Paper>
						</Container>

						{result?.messages?.length > 0 && (
							<ShowMessageList
								sharedSecret={sharedSecret}
								messages={result?.messages}
								controllerName={result?.clientName}
							/>
						)}

						<div className='mb-4'>
							<SendMessageButton
								buttonOrientation='right'
								sharedSecret={sharedSecret}
								controllerName={result?.clientName}
								externalRequestId={parsedParams.id}
								onMessageSent={handleRefreshRequestData}
							/>
						</div>
					</>
				)}
				{showResults && (
					// if password was correct (fetched data has success == =true) show details; else show bounce message

					<>
						<ShowResult
							id={parsedParams.id}
							data={result}
							sharedSecret={sharedSecret}
						/>
					</>
				)}
				{/* if request was rejected, show error message */}
				{showNotAuthorized && (
					<>
						<Container maxWidth='lg' className={classes.container}>
							<Paper className='card shadow-sm border-0 mt-5 mb-3 text-start p-4'>
								{/* <h3 className='mb-5'>Ihre Betroffenenrechtsanfrage</h3> */}
								<p>
									<b>Sie sind nicht berechtigt diese Anfrage einzusehen.</b>
								</p>
								<p>
									Bitte prüfen Sie, ob Anfrage-ID sowie Passwort korrekt sind.
								</p>
							</Paper>
						</Container>
					</>
				)}
				{showNotFound && (
					<>
						<Container maxWidth='lg' className={classes.container}>
							{/* <Paper className='card card-box border-0 mt-5 mb-3 text-start p-4 '>
								<h3 className='mb-5'>Ihre Betroffenenrechtsanfrage</h3>
								<div className='text-left'> */}
							<MuiAlert severity='error'>
								<b>Leider konnten wir zu Ihrer Anfrage keine Daten finden.</b>
								<p>
									Bitte stellen Sie sicher, dass Sie einen korrekten Link
									benutzt haben und die Bearbeitung Ihre Anfrage nicht vor mehr
									als 30 Tagen abgeschlossen wurde.
								</p>
							</MuiAlert>
							{/* </div>
							</Paper> */}
						</Container>
					</>
				)}
			</MuiThemeProvider>
		</div>
	);
}

export default App;
