import '../App.css';

import React, { useState, useEffect } from 'react';

import dateFormat from 'dateformat';

import { makeStyles } from '@material-ui/core/styles';
import {
	Paper,
	Grid,
	Box,
	Container,
	TextField,
	Button,
	Collapse,
	Divider,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { css } from '@emotion/react';

import {
	aesEncrypt,
	aesDecrypt,
	deriveSecretFromPassword,
	generateClientKeys,
	generateKeyPair,
	computeSharedSecret,
	createHMAC,
} from '../services/cryptoService';

import PasswordStrengthBar from 'react-password-strength-bar';

import requestTypes from '../config/requestTypes';
import { useFormik } from 'formik';

import { createSharedSecretFromFreshKeypair } from '../services/generateSecret';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { generateApiToken } = require('../services/generateApiToken');

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: theme.spacing(160),
			height: theme.spacing(200),
		},
	},
	content: {
		textAlign: 'left',
		padding: theme.spacing(3),
	},
	container: {
		marginTop: theme.spacing(2),
	},

	paper: {
		textAlign: 'left',
		padding: theme.spacing(8),
		marginBottom: theme.spacing(1),
	},
	// textField: {
	// 	paddingBottom: 0,
	// 	marginTop: 0,
	// 	marginBottom: 2,
	// 	fontWeight: 500,
	// 	borderBottom: '1px solid #0f4354',
	// },
	input: {},
}));

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

function InitiateIdentification(props) {
	const classes = useStyles();

	const { data, onSubmitted } = props;
	const oneTimePassword = props.oneTimePassword.replaceAll(' ', '+');

	const [loading, setLoading] = useState(true);
	const [sharedSecret, setSharedSecret] = useState();
	const [userPublicKey, setUserPublicKey] = useState();

	const [encryptedUserPrivateKey, setEncryptedUserPrivateKey] = useState();
	const [secretFromPassword, setSecretFromPassword] = useState();
	const [saltFromPassword, setSaltFromPassword] = useState();
	const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
	const [operationInProgress, setOperationInProgress] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [identificationOneTimePassword, setIdentificationOneTimePassword] =
		useState(oneTimePassword);

	const [identificationParameterValues, setIdentificationParameterValues] =
		useState([]);

	// const [password1, setPassword1] = useState('');
	// const [password2, setPassword2] = useState('');

	const formik = useFormik({
		initialValues: {
			password1: '',
			password2: '',
			score: 0,
			oneTimePassword: oneTimePassword,
		},
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const dateOptions = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	let requestDate = undefined;
	let requestType = undefined;

	if (data) {
		// requestTypes[data?.requestType][data?.preferredLanguage || 'de']?.label;
		requestType = requestTypes[0]['de'].label;

		// console.log('requestDate:', data?.requestDate);
		// console.log('creationDate:', data?.creationDate);

		requestDate = new Date(data?.requestDate).toLocaleDateString(
			data?.preferredLanguage || 'de',
			dateOptions
		);
	}

	const handleIdentificationParameterValueChanges = (event) => {
		setIdentificationParameterValues({
			...identificationParameterValues,
			// 	.filter(
			// 	(p) => p.identificationParameter !== event.target.name
			// ),
			[event.target.name]: event.target.value,
		});
	};

	const generateKeys = async (clientPublicKey) => {
		if (clientPublicKey)
			return await createSharedSecretFromFreshKeypair(data?.clientPublicKey);
		return null;
	};

	const encryptIdentificationValues = async (sharedSecret) => {
		if (sharedSecret) {
			const encryptedIdentificationParameterValues = {};

			Object.keys(identificationParameterValues).map((key) => {
				encryptedIdentificationParameterValues[key] = aesEncrypt(
					identificationParameterValues[key],
					sharedSecret
				);
			});

			return encryptedIdentificationParameterValues;
		}
		return null;
	};

	const sendIdentificationParameterValuesToBackend = async (
		identificationParameterValues,
		encryptedUserPrivateKey,
		userPublicKey,
		userSecretSalt,
		keyTest,
		identificationOneTimePassword,
		identificationReference
	) =>
		new Promise((resolve, reject) => {
			console.log('Values to send:');
			console.log(
				'identificationParameterValues:',
				identificationParameterValues
			);
			console.log(
				'Identification Reference encrypted: ' + identificationReference
			);
			console.log('OneTimePassword:', identificationOneTimePassword);
			console.log('encryptedUserPrivateKey:', encryptedUserPrivateKey);
			console.log('userPublicKey:', userPublicKey);
			console.log('keyTest:', keyTest);

			generateApiToken(data.requestId, keyTest).then((apiToken) => {
				console.log('apiToken:', apiToken);

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						requestToken: apiToken,
						externalRequestId: data.requestId,
						identificationOneTimePassword: identificationOneTimePassword
							.trim()
							.replaceAll(' ', '+'),
						identificationParameterValuesArray: identificationParameterValues,
						encryptedUserPrivateKey,
						userPublicKey,
						userSecretSalt,
						keyTest,
						identificationReference,
					}),
				};
				setLoading(true);
				fetch(
					`${process.env.REACT_APP_ENDPOINT}/request/provide-identification-parameters`,
					requestOptions
				)
					.then((response) => {
						// if (response.status === 200) return response.json();
						// else resolve({ status: response.status });

						return { ...response.json(), status: response?.status };
					})
					.then((data) => {
						// console.log("Data:", data);
						if (data) {
							// resolve(data.response);
							//setResult(data.response);
							setLoading(false);
							console.log('Fetched response status:', data);
							resolve(data);
						}
					})
					.catch((error) => {
						//setResult('Error while fetching Status.');
						setLoading(false);
						console.log(error);
						reject(error);
					});
			});
		});

	const handleGenerateSecretsButton = (event) => {
		if (formik.values.password1 && data.clientPublicKey)
			generateSecretsFromPassword(
				formik.values.password1,
				data.clientPublicKey
			);
	};

	const generateSecretsFromPassword = async (password, clientPublicKey) => {
		setOperationInProgress(true);

		// 1) generate salt and hash user's password
		// 2) generate keypair
		// 3) compute shared secret with clientpublickey and userPrivateKey
		// 4) encrypt identification values with shared secret
		// 5) encrypt clientprivatekey with password hash

		if (password) {
			// 1) generate salt and hash user's password
			const userPassword = await deriveSecretFromPassword(
				formik.values.password1
			);
			// console.log('userPassword:', userPassword);

			// 2) generate keypair
			const keypair = await generateKeyPair();
			// console.log('keypair:', keypair);

			// 3) compute shared secret with clientpublickey and userPrivateKey
			const theSharedSecret = await computeSharedSecret(
				clientPublicKey,
				keypair.privateKey
			);
			// console.log('sharedSecret:', theSharedSecret);

			// 4) encrypt identification values with shared secret
			// done at sending data to server

			// 5) encrypt clientprivatekey with password hash
			const encryptedUserPrivateKey = await aesEncrypt(
				keypair.privateKey,
				userPassword.secret
			);
			// console.log('encryptedUserPrivateKey:', encryptedUserPrivateKey);

			const kt = aesEncrypt('OK', theSharedSecret);
			// const revKt = aesDecrypt(kt, theSharedSecret);

			// console.log('KeyTest', kt, revKt);

			// const hmac = await createHMAC('OK', theSharedSecret);
			// const hmac2 = await createHMAC('OK', theSharedSecret);
			// console.log('HMAC', hmac, hmac2);

			setSecretFromPassword(userPassword.secret);
			setSaltFromPassword(userPassword.salt);
			setSharedSecret(theSharedSecret);
			setEncryptedUserPrivateKey(encryptedUserPrivateKey);
			setUserPublicKey(keypair.publicKey);
		}

		setOperationInProgress(false);
	};

	const reEncryptIdentificationReference = async (
		oldEncryptedIdentificationReference,
		newSharedSecret,
		oldUserPrivateKey,
		clientPublicKey
	) => {
		console.log('oldUserPrivateKey', oldUserPrivateKey);
		console.log('clientPublicKey', clientPublicKey);

		const oldSharedSecret = await computeSharedSecret(
			clientPublicKey,
			oldUserPrivateKey
		);
		console.log('Old SharedSecret:', oldSharedSecret);

		console.log(
			'Encrypted identification reference:',
			oldEncryptedIdentificationReference
		);

		const decryptedIdentificationReference = aesDecrypt(
			oldEncryptedIdentificationReference,
			oldSharedSecret
		);

		console.log(
			'Decrypted Identification Reference:',
			decryptedIdentificationReference
		);

		const reEncryptedIdentificationReference = aesEncrypt(
			decryptedIdentificationReference,
			newSharedSecret
		);
		console.log('New shared secret', newSharedSecret);
		console.log(
			'Re-encrypted identification reference',
			reEncryptedIdentificationReference
		);
		console.log(
			'Test: re-decrypted identification reference',
			aesDecrypt(reEncryptedIdentificationReference, newSharedSecret)
		);

		return reEncryptedIdentificationReference;
	};

	const handleSendValuesButtonClick = async (event) => {
		event.preventDefault();

		// const keychain = await generateKeys(data?.clientPublicKey);
		// console.log('Keychain:', keychain);

		const encryptedValues = await encryptIdentificationValues(sharedSecret);
		const identificationReference =
			data.identificationReference &&
			(await reEncryptIdentificationReference(
				data.identificationReference,
				sharedSecret,
				identificationOneTimePassword,
				data.clientPublicKey
			));
		// console.log('sharedSecret:', sharedSecret);
		// console.log('encryptedValues:', encryptedValues);

		const result = await sendIdentificationParameterValuesToBackend(
			encryptedValues,
			encryptedUserPrivateKey,
			userPublicKey,
			saltFromPassword,
			await createHMAC('OK', sharedSecret),
			identificationOneTimePassword,
			identificationReference
		);

		console.log('Result from API:', result);

		if (result?.status === 401) {
			setErrorMessage(
				'Sie sind nicht berechtigt, die Angaben zur Identifizierung zu hinterlegen. Bitte prüfen Sie, ob das einmalige Passwort korrekt eingegeben wurde.'
			);
			setIdentificationOneTimePassword(undefined);
		}
		if (result?.status === 200) {
			setShowSuccessMessage(true);
		}

		if (result?.success && onSubmitted) onSubmitted();
	};

	return (
		<div>
			<Container maxWidth='lg' className={classes.container}>
				<Paper className='card card-box border-0 mt-5 mb-3 text-start p-4'>
					<h3>Ihre Betroffenenrechtsanfrage</h3>
					<Divider className='mb-3 mt-2' />
					<p>
						Sehr gehrte Nutzerin, sehr geehrter Nutzer,
						<br />
						<br />
						Wir haben Ihre {requestType} von&nbsp;
						{requestDate} erhalten.
						<br />
						<br />
						Da die Sicherheit Ihrer personenbezogenen Daten unser höchstes
						Anliegen ist, müssen wir zunächst sicherstellen, dass diese Anfrage
						tatsächlich von Ihnen stammt und wir Nachrichten und Informationen
						mit Ihnen über diesen Kanal verschlüsselt austauschen können.
						<br />
						<br />
						Die Kommunikation im Rahmen der Bearbeitung Ihrer Anfrage erfolgt
						unterstützt durch unseren Dienstleisters PRIVAPI GmbH. Die
						Datenschutzhinweise finden Sie{' '}
						<a href='https://www.privapi.io/datenschutz'>hier</a>. Der
						Dienstleister hat zu keinem Zeitpunkt Zugriff auf die zwischen uns
						ausgetauschten personenbezogenen Daten.
					</p>
				</Paper>
				{!props.oneTimePassword && (
					<Paper className='card card-box border-0 mb-3 text-start p-4'>
						{/* <div class='numberCircleSmall'>1</div> */}
						<h5 className='mb-0'>Berechtigung prüfen</h5>
						<Collapse in={!identificationOneTimePassword || errorMessage}>
							<p className='mt-4'>
								Um die Angaben für eine Identifizierung erfassen zu können,
								benötigen Sie ein einmaliges Passwort. Nutzen Sie daher bitte
								möglichst den Link aus der Email-Benachrichtigung, um diese
								Seite aufzurufen. Alternativ können Sie ein erhaltenes
								einmaliges Passwort hier eingeben.
							</p>

							<TextField
								fullWidth
								id='oneTimePassword'
								name='oneTimePassword'
								label='Einmaliges Passwort für die Identifizierung'
								className={classes.textField}
								value={formik.values.oneTimePassword}
								onChange={formik.handleChange}
								error={formik.errors.oneTimePassword}
								helperText={formik.errors.oneTimePassword}
							/>
							<Button
								fullWidth
								variant='contained'
								className=' mt-3 mb-2'
								onClick={() => {
									setErrorMessage(undefined);
									formik.setFieldValue('password1', undefined);
									setIdentificationOneTimePassword(
										formik.values.oneTimePassword
									);
								}}
								color='primary'
							>
								Einmaliges Passwort eingeben
							</Button>
						</Collapse>
					</Paper>
				)}
				{/* {identificationOneTimePassword && !errorMessage && ( */}
				<Paper className='card card-box border-0 mb-3 text-start p-4'>
					{/* <div class='numberCircleSmall'>1</div> */}
					<h5 className='mb-0'>Eigenes Passwort festlegen</h5>
					<Collapse in={identificationOneTimePassword && !errorMessage}>
						<p className='mt-4'>
							Legen Sie bitte nun ein eigenes Passwort fest. Dies ermöglicht es,
							Nachrichten und Daten verschlüsselt austauschen. Bitte merken Sie
							Sich das Passwort oder speichern Sie es z.B. in einem
							Passwortmanager. Bitte verwenden Sie Groß- und Kleinschreibung
							sowie Sonderzeichen.
						</p>

						<TextField
							fullWidth
							id='password1'
							name='password1'
							label='Passwort'
							className={classes.textField}
							value={formik.values.password1}
							onChange={formik.handleChange}
							error={formik.errors.password1}
							helperText={formik.errors.password1}
							disabled={secretFromPassword}
						/>

						{!secretFromPassword ? (
							<>
								<PasswordStrengthBar
									password={formik.values.password1}
									id='score'
									className='mb-3'
									onChangeScore={(score) => {
										console.log('score:', score);
										setPasswordStrengthScore(score);
									}}
									// onChangeScore={formik.handleChange}
								/>

								<Button
									id='buttonProvideNewPassword'
									fullWidth
									variant='contained'
									className=''
									onClick={handleGenerateSecretsButton}
									disabled={
										passwordStrengthScore < 4 ||
										operationInProgress ||
										secretFromPassword
									}
									color='primary'
								>
									{operationInProgress ? (
										// <FontAwesomeIcon icon='fa-duotone fa-circle-notch' />
										<div>
											<FontAwesomeIcon icon='fa-solid fa-circle-notch' spin />
										</div>
									) : (
										// <FontAwesomeIcon icon={['fas', 'check-circle']}></FontAwesomeIcon>
										'Passwort festlegen'
									)}
								</Button>
							</>
						) : (
							<MuiAlert severity='warning' className='mt-3'>
								Bitte denken Sie daran, Ihr Passwort sicher zu verwahren. Im
								Fall eines Verlusts können Sie nicht mehr auf Ihre Anfrage
								zugreifen.
							</MuiAlert>
						)}
					</Collapse>
				</Paper>
				{/* )} */}
				{/* {!showSuccessMessage && !errorMessage && identificationOneTimePassword && ( */}
				<Paper className='card card-box border-0 mt-3 mb-3 text-start p-4'>
					<h5 className='font-weight-bold mb-0'>
						Angaben für die Identifizierung
					</h5>
					<Collapse
						in={
							!showSuccessMessage &&
							!errorMessage &&
							identificationOneTimePassword &&
							secretFromPassword
						}
					>
						<p className='mt-4'>
							Um eine Identifizierung durchzuführen, benötigen wir von Ihnen
							nachfolgende Angaben. Alle Angaben werden verschlüsselt übertragen
							und gespeichert.
						</p>

						{data?.identificationParameters?.map((p) => {
							return (
								<div key={p._id} className='mb-4'>
									<h6>{p.translations[0].title}</h6>
									{p.translations[0].description && (
										<div className='text-black-50'>
											{p.translations[0].description}
										</div>
									)}
									<TextField
										id='textfieldIdentificationParameter'
										name={p._id}
										fullWidth
										// label={p.translations[0].title}
										value={identificationParameterValues[p._id] || ''}
										onChange={handleIdentificationParameterValueChanges}
									/>
								</div>
							);
						})}
						<MuiAlert severity='info' className='mb-4 mt-3'>
							Ohne positive Identifizierung können wir Ihre Anfrage nicht weiter
							bearbeiten. Bitte machen Sie daher die erforderlichen Angaben.
						</MuiAlert>

						<Button
							id='buttonSendIdentificationValues'
							// onClick={formik.handleSubmit}
							onClick={handleSendValuesButtonClick}
							variant='contained'
							color='primary'
						>
							Absenden
						</Button>
					</Collapse>
				</Paper>
				{/* )} */}
				<Collapse in={showSuccessMessage}>
					<Paper className='card card-box border-0 mb-3 text-start p-4'>
						{/* <div class='numberCircleSmall'>1</div> */}
						<h5>Ihre Anfrage wird nun bearbeitet</h5>

						<MuiAlert severity='success' className='mt-4'>
							Wir haben Ihre Angaben erhalten und senden Ihnen eine E-Mail,
							sobald Ihre Anfrage abschließend bearbeitet wurde. Sie können
							dieses Fenster nun schließen.
						</MuiAlert>
					</Paper>
				</Collapse>
				<Collapse in={errorMessage}>
					<Paper className='card card-box border-0 mb-3 text-start p-4'>
						{/* <div class='numberCircleSmall'>1</div> */}
						<h5 style={{ color: 'red' }}>Es ist ein Fehler aufgetreten</h5>
						<p className='mt-4'>{errorMessage}</p>
					</Paper>
				</Collapse>
			</Container>
			{/* <p className=''>
					Unterstützt durch die PRIVAPI GmbH (Datenschutzhinweise)
				</p> */}
		</div>
	);
}

export default InitiateIdentification;
