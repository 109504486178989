import DOMPurify from 'dompurify';
import edjsParser from 'editorjs-parser';
import { aesDecrypt } from './cryptoService';

// extracts, decrypts and sanitizes a given message
export const prepareMessage = (message, sharedSecret) => {
	console.log('Message: ', message);
	console.log('Shared Secret', sharedSecret);

	try {
		const sanitizer = DOMPurify.sanitize;

		let headline = '';
		let messageBody = '';
		let fullMessage = '';

		let msg = message?.encrypted
			? aesDecrypt(message.message, sharedSecret)
			: message.message;

		console.log('Message: ', msg);

		// if message is in editrJs format, parse it to html
		if (message?.format === 'editorjs') {
			let messageObject = JSON.parse(msg);
			console.log('Message as object before html parser:', messageObject);

			let headlineBlocks = messageObject.blocks.filter(
				(block) => block.type === 'header'
			);

			headline =
				headlineBlocks?.length > 0
					? headlineBlocks[0]?.data?.text
					: 'Ihre Nachricht';
			console.log('Headline:', headline);

			messageBody = {
				...messageObject,
				blocks: messageObject.blocks.filter(
					(b) => b.id !== headlineBlocks[0]?.id
				),
			};
			console.log('Stripped message body:', messageBody);

			let parser = new edjsParser();

			messageBody = parser.parse(messageBody);
			fullMessage = parser.parse(messageObject);
		}

		return {
			headline: headline,
			body: messageBody,
			message: sanitizer(fullMessage),
		};
	} catch (error) {
		console.log('Error while parsing message: ', error);

		return {
			headline: '',
			body: '',
			message: '',
		};
	}
};

export const getMessagesAsHTMLTable = (
	messages,
	sharedSecret,
	controllerName
) => {
	if (messages?.length > 0) {
		let html =
			'<table><thead><tr><h3>Ausgetauschte Mitteilungen</h4><br>&nbsp;</tr></thead><tbody>';

		messages.forEach((message, index) => {
			let msg = prepareMessage(message, sharedSecret);

			if (index > 0) html += `<tr><td><hr></td></tr><tr><td>&nbsp;</td></tr>`;

			if (msg.headline) {
				html += `<tr><td><b>${msg.headline}</b></td></tr>`;
			}

			if (!message.author || message.author === '') {
				html += `(
					<tr>
						<td><b>Systemgenerierte Mitteilung vom Verantwortllichen</b></td>
					</tr>
				)`;

				if (!message.type == 10) {
					html += `(
					<tr>
						<td><b>Ihre Mitteilung an ${controllerName || 'den Verantwortlichen'}</b></td>
					</tr>
				)`;
				}

				if (!message.type == 20) {
					html += `(
                        <tr>
                            <td><b>Mitteilung ${
															(controllerName && 'vom' + controllerName) ||
															'vom Verantwortlichen'
														}</b></td>
                        </tr>
                    )`;
				}

				if (!message.type == 30) {
					html += `(
                            <tr>
                                <td><b>Ihre Mitteilung an ${
																	(controllerName && controllerName) ||
																	'den Verantwortlichen'
																}</b></td>
                            </tr>
                        )`;
				}
			} else {
			}

			html += `<tr>
            <td>${message.date}</td>
            </tr>`;
			// if (msg.headline !== '') {
			// 	html += `
			// <tr>
			// <td>${msg.headline}</td>
			// </tr > `;
			// }
			html += `<tr><td>&nbsp;</td></tr>`;
			html += `<tr><td>${msg.body}</td></tr>`;
		});

		html += '</tbody></table>';
		return html;
	}
	return '';
};

// module.exports = { prepareMessage, getMessagesAsHTMLTable };
