import { Button } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import EditorJs from 'react-editor-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThumbDownSharp } from '@material-ui/icons';

// import { EDITOR_JS_TOOLS } from './constants';

// customTools={EDITOR_JS_TOOLS}

// const editorDefaultValue = JSON.stringify({
// 	blocks: [
// 		{
// 			type: 'header',
// 			data: {
// 				text: 'Meine Anfrage vom...',
// 				level: 4,
// 			},
// 		},
// 		{
// 			type: 'paragraph',
// 			data: {
// 				text: 'Bitte hier Ihre Nachricht verfassen.',
// 			},
// 		},
// 	],
// });

export default class BlockEditor extends Component {
	constructor(props) {
		super(props);
		console.log('Props Data:', this.props);
	}

	handleSaveMessage = async (event) => {
		event.preventDefault();
		const data = await this.editorInstance.save();
		console.log('Data:', data);
		this.props.onSave(data);
	};

	handleClose = (event) => {
		event.preventDefault();
		this.editorInstance.clear();
		this.props.onClose && this.props.onClose();
	};

	render() {
		return (
			<Fragment>
				<div className=''>
					<EditorJs
						instanceRef={(instance) => (this.editorInstance = instance)}
						// tools={EDITOR_JS_TOOLS}
						enableReInitialize={false}
						minHeight={50}
						// data={editorDefaultValue}
						placeholder='Nachricht hier verfassen...'
						// onReady={() => {
						// 	console.log('Editor.JS is ready to work!');
						// 	// this.editorInstance.render();
						// }}
					/>
					<div className='d-flex justify-content-between'>
						<Button variant='outlined' size='small' onClick={this.handleClose}>
							Abbrechen
						</Button>
						<Button
							variant='outlined'
							size='small'
							onClick={this.handleSaveMessage}
						>
							{this.props?.showSaveButtonSpinner ? (
								// <FontAwesomeIcon icon='fa-duotone fa-circle-notch' />
								<div>
									<FontAwesomeIcon icon='fa-solid fa-circle-notch' spin />
								</div>
							) : (
								// <FontAwesomeIcon icon={['fas', 'check-circle']}></FontAwesomeIcon>
								this.props.saveButtonCaption || 'Speichern'
							)}
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}
