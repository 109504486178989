import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import 'bootstrap/dist/css/bootstrap.css';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (
	process.env.REACT_APP_ENV === 'prod' ||
	process.env.REACT_APP_ENV === 'PROD'
) {
	// disable React Dev Tools for production
	disableReactDevTools();

	// deactivate logging on PROD
	console.log = () => {};

	// enable sentry error logging
	Sentry.init({
		dsn: 'https://48db0210794e47d59a2351fe44fa2ab8@o1071225.ingest.sentry.io/4504094767054848',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
