const { v4: uuidv4 } = require('uuid');
const cryptobro = require('crypto-browserify');

// export function generateApiToken() {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			console.log('Generating Token...');
// 			// 1) Create NONCE
// 			const nonce = uuidv4();

// 			// 2) HASH API KEY SECRET with NONCE & timestamp
// 			const timestamp = new Date().getTime();

// 			// Defining key

// 			// Calling createHash method
// 			const hash = cryptobro
// 				.createHash('sha256', this.apiKeySecret)

// 				// updating data
// 				.update(this.apiKeySecret + timestamp.toString() + nonce)

// 				// Encoding to be used
// 				.digest('base64');

// 			// Displays output
// 			//console.log(hash);

// 			resolve({
// 				apiKeyId: this.apiKeyId,
// 				hash: hash,
// 				timestamp: timestamp.toString(),
// 				nonce: nonce,
// 			});
// 		} catch (error) {
// 			console.error(error.message);

// 			reject(error.message);
// 		}
// 	});
// }

// export function generateAccessTokenFromExternalRequestId(
// 	externalRequestId,
// 	originalRequestDate
// ) {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			console.log('Original request date:', originalRequestDate);

// 			// 1) Create NONCE
// 			const nonce = uuidv4();

// 			// 2) HASH API KEY SECRET with NONCE & timestamp
// 			const timestamp = new Date().getTime();

// 			// Calling createHash method
// 			const hash = cryptobro
// 				.createHash('sha256', originalRequestDate)

// 				// updating data
// 				.update(originalRequestDate + timestamp.toString() + nonce)

// 				// Encoding to be used
// 				.digest('base64');

// 			// Displays output
// 			//console.log(hash);

// 			resolve({
// 				externalRequestId: externalRequestId,
// 				hash: hash,
// 				timestamp: timestamp.toString(),
// 				nonce: nonce,
// 			});
// 		} catch (error) {
// 			console.error(error.message);

// 			reject(error.message);
// 		}
// 	});
// }

export const generateApiToken = (externalRequestId, secret) => {
	return new Promise((resolve, reject) => {
		try {
			// 1) Create NONCE
			const nonce = uuidv4();
			console.log('Creating NONCE:', nonce);

			// 2) HASH API KEY SECRET with NONCE & timestamp
			const timestamp = new Date().getTime();
			console.log('Creating timestamp:', timestamp);

			// Defining key

			// Calling createHash method
			const hash = cryptobro
				.createHash('sha256', secret)

				// updating data
				.update(secret + timestamp.toString() + nonce)

				// Encoding to be used
				.digest('base64');
			console.log('Creating hash:', hash);

			resolve({
				externalRequestId,
				hash,
				timestamp: timestamp.toString(),
				nonce,
			});
		} catch (error) {
			console.error(error.message);

			reject(error.message);
		}
	});
};
